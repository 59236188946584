<template>
  <div>
    <div class="position-relative overflow-hidden bg-light">
      <div class="col-md-8 p-lg-5 mx-auto my-5">
        <h1 class="display-4 font-weight-normal">Comentários e amigos</h1>
        <p class="lead font-weight-normal">
          Comente nos rounds apenas para seu time ou amigos.
          <!-- <strong>aquele round</strong> que você precisa. -->
        </p>
      </div>
    </div>
    <!-- <div class="container spaced">
      <div class="row">
        <div class="col-md-6 col">
          <img src="https://media.giphy.com/media/SJm0bQHxzFhEHUDqAD/giphy.gif" alt>
        </div>
        <div class="col-md-6 col">
          <h4>Você pode filtrar por</h4>

          <ul>
            <li>Mapa</li>
            <li>Economia do Round</li>
            <li>Lado (terroristas/CTs)</li>
            <li>Time</li>
          </ul>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "FeaturesSocial",
  metaInfo() {
    return {
      title: "Comentários e amigos"
    };
  }
};
</script>

<style lang="sass" scoped>
.container.spaced
  .col
    padding: 40px
  // .col
</style>
